import React, { useEffect, useState } from "react";
import { getUserData, getUserHistory } from "../../controllers/userController";
import logo from "../../assets/icons/logo-banco-da-felicidade.svg";
import coin1 from "../../assets/icons/moeda-1.svg";
import coin5 from "../../assets/icons/moeda-5.svg";
import coin10 from "../../assets/icons/moeda-10.svg";
import coin50 from "../../assets/icons/moeda-50.svg";
import coin100 from "../../assets/icons/moeda-100.svg";
import { Link } from "react-router-dom";
import giftIcon from "../../assets/icons/icon-gift.svg";
import { formatDate, formatTime, sumMoedas } from "../../utils";
import { Container, ContentSection, Content } from "../../styles/styles";
import iconPrizes from "../../assets/icons/icon-prizes.svg";
import iconHistory from "../../assets/icons/icon-history.svg";
import iconRanking from "../../assets/icons/icon-ranking.svg";
import iconGive from "../../assets/icons/icon-give.svg";
import iconRemove from "../../assets/icons/icon-remove.svg";
import Footer from "../../components/footer";
import GlobalStyle from "../../styles/global";
import { Header, HistoricoSection, HomeContent, styles } from "./styles";
import { FaRegBell } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";

function Home() {
  const [userData, setUserData] = useState({});
  const [moedasDistribuidas, setMoedasDistribuidas] = useState({});
  const [history, setHistory] = useState([]);

  const user = JSON.parse(localStorage.getItem("user")) || {};

  useEffect(() => {
    const fetchData = async () => {
      if (user?.idusuario) {
        const userInfo = await getUserData(user.idusuario);
        setUserData(userInfo);
        setMoedasDistribuidas(JSON.parse(userInfo.moedas_a_distribuir));

        const userHistory = await getUserHistory(user.idusuario);
        setHistory(userHistory);
      }
    };

    fetchData();
  }, [user?.idusuario]);

  return (
    <>
      <GlobalStyle />
      <Header>
        <div className="headerButtons">
          <img src={logo} alt="Logo Banco da Felicidade" className="logo" />
          <button>
            <FaRegBell />
          </button>
        </div>
        <div className="info">
          <FaCircleUser />
          <div className="userInfo">
            <h1 className="headerText">Bem-vindo de volta!</h1>
            <h2 className="userName">{userData.nome}</h2>
            <p className="userDetails">{userData.unidade}</p>
            <p className="userDetails">{userData.cargo}</p>
          </div>
          <div className="saldo">
            <h3 className="saldoTitle">Meu saldo</h3>
            <h2 className="saldoValue">{userData.saldo_moedas}</h2>
          </div>
        </div>
      </Header>

      <div style={styles.moedasSection}>
        {user.nivel != 0 && (
          <>
            {user.nivel === 1 ? (
              <p style={styles.sectionTitle}>Moedas para distribuir:</p>
            ) : (
              <p style={styles.sectionTitle}>Moedas para emocionar:</p>
            )}
            <div style={styles.moedasContainer}>
              {user.nivel === 1 ? (
                <p style={{ color: "black" }}>Ilimitado</p>
              ) : (
                <>
                  <div style={styles.moedaItem}>
                    <img src={coin1} alt="1 Moeda" style={styles.moedaIcon} />
                    <p style={styles.moedaText}>
                      {moedasDistribuidas["1"] || 0}
                    </p>
                  </div>
                  <div style={styles.moedaItem}>
                    <img src={coin5} alt="5 Moedas" style={styles.moedaIcon} />
                    <p style={styles.moedaText}>
                      {moedasDistribuidas["5"] || 0}
                    </p>
                  </div>
                  <div style={styles.moedaItem}>
                    <img
                      src={coin10}
                      alt="10 Moedas"
                      style={styles.moedaIcon}
                    />
                    <p style={styles.moedaText}>
                      {moedasDistribuidas["10"] || 0}
                    </p>
                  </div>
                  <div style={styles.moedaItem}>
                    <img
                      src={coin50}
                      alt="50 Moedas"
                      style={styles.moedaIcon}
                    />
                    <p style={styles.moedaText}>
                      {moedasDistribuidas["50"] || 0}
                    </p>
                  </div>
                  <div style={styles.moedaItem}>
                    <img
                      src={coin100}
                      alt="100 Moedas"
                      style={styles.moedaIcon}
                    />
                    <p style={styles.moedaText}>
                      {moedasDistribuidas["100"] || 0}
                    </p>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>

      <HomeContent>
        {user.nivel != 0 ? (
          <>
            <Link to="/emocionar" className="emocionarSection">
              <button className="emocionarButton">Emocionar</button>
            </Link>
          </>
        ) : null}

        <Container
          justify="center"
          align="center"
          direction="row"
          style={{ gap: "20px" }}
        >
          {user.nivel == 1 ? (
            <>
              <Link to="/dashboard">
                <img src={iconRanking} alt="Ranking" />
              </Link>
                <Link to="/remover-moedas">
                <img src={iconRemove} alt="Remove" />
              </Link>
            </>
          ) : null}

          {user.nivel == 1 ? (
            <>
              <Link to="/distribuir-moedas">
                <img src={iconGive} alt="Destribuir" />
              </Link>
            </>
          ) : null}
          <Link to="/premios">
            <img src={iconPrizes} alt="Prêmios" />
          </Link>
          <Link to="/">
            <img src={iconHistory} alt="Históricos" />
          </Link>
        </Container>

        <ContentSection fullWidth>
          <Content>
            <h3 style={styles.Title}>Histórico</h3>
            <HistoricoSection>
              {history.map((item, index) => {
                const soma = sumMoedas(item.amount);
                return (
                  <div key={index} className="historicoItem">
                    <div className="itemType">
                      {item.type === "resgate" ? (
                        <img
                          src={giftIcon}
                          alt={item.type}
                          className="imgIcon"
                        />
                      ) : (
                        <img src={logo} alt={item.type} className="imgIcon" />
                      )}
                      <h5 className="historicoName">
                        {item.nome}
                        {item.type === "resgate" && <span>x {item.qtd}</span>}
                        <div className="historicoDate">
                          <p>{formatTime(item.date)}</p>
                          <p>{formatDate(item.date)}</p>
                        </div>
                      </h5>
                    </div>
                    <p className="historicoAmount">
                      {soma > 0 ? `+${soma}` :  + `-${item.amount}`}
                    </p>
                  </div>
                );
              })}
            </HistoricoSection>
          </Content>
        </ContentSection>
        <Footer />
      </HomeContent>
    </>
  );
}

export default Home;
