import Inputmask from "inputmask";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { createUser } from "../../controllers/userController"; // Função que vamos criar
import GlobalStyle, { BackBar, BackButton } from "../../styles/global";
import { Container, Content, ContentSection } from "../../styles/styles";
import { Form, InputField, Label, SelectField, SubmitButton } from "./styles";

const UserAdd = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [cpf, setCpf] = useState("");
  const [cargo, setCargo] = useState("");
  const [unidade, setUnidade] = useState("");
  const [secao, setSecao] = useState("");
  const [setor, setSetor] = useState("");

  useEffect(() => {
    Inputmask("999.999.999-99").mask(document.getElementById("cpf"));
  }, []);

  const removeMaskCPF = (cpf) => {
    return cpf.replace(/\D/g, "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (senha !== confirmarSenha) {
      toast.error("As senhas não conferem.");
      return;
    }

    const cpfSemMascara = removeMaskCPF(cpf);

    const formData = {
      nome,
      email,
      senha,
      cpfSemMascara,
      cargo,
      unidade,
      secao,
      setor,
    };

    try {
      await createUser(formData);
      toast.success("Usuário atualizado com sucesso!", {
        autoClose: 3000,
        onClose: () => navigate("/"),
      });
    } catch (error) {
      toast.error("Erro ao criar o usuário: " + error.mensagem);
    }
  };

  return (
    <>
      <ToastContainer />
      <GlobalStyle />
      <Container
        style={{
          background: `linear-gradient(10deg, #34C759 20%, #00A565 0%)`,
        }}
        fullWidth
        height="100%"
        direction="column"
        align="center"
        padding="0px"
      >
        <Container fullWidth direction="column" gap="40px">
          <BackBar>
            <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
            <BackButton onClick={() => navigate("/")}>X</BackButton>
          </BackBar>

          <ContentSection fullWidth padding="0px">
            <Content
              light
              sx={{ paddingTop: "40px", overflowY: "auto" }}
              padding="0px"
            >
              <Form onSubmit={handleSubmit}>
                <p>Dados do Emocionador</p>
                <Container
                  fullWidth
                  direction="column"
                  gap="18px"
                  padding="20px"
                >
                  <Label htmlFor="nome">Nome</Label>
                  <InputField
                    type="text"
                    placeholder="Nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    required
                  />

                  <Label htmlFor="cpf">CPF</Label>
                  <InputField
                    type="text"
                    placeholder="CPF"
                    id="cpf"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    required
                  />

                  <Label htmlFor="email">E-mail</Label>
                  <InputField
                    type="email"
                    placeholder="Email do usuário"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <Label htmlFor="password">Nova senha</Label>
                  <InputField
                    type="password"
                    placeholder="Senha"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                    required
                  />

                  <Label htmlFor="confirm">Confirmar senha</Label>
                  <InputField
                    type="password"
                    placeholder="Confirmar Senha"
                    value={confirmarSenha}
                    onChange={(e) => setConfirmarSenha(e.target.value)}
                    required
                  />

                  <p>Onde emociona?</p>

                  <Label htmlFor="unidade">Unidade</Label>
                  <SelectField
                    value={unidade}
                    onChange={(e) => setUnidade(e.target.value)}
                    required
                  >
                    <option value="">Unidade</option>
                    <option value="Holding">Holding</option>
                    <option value="Alegro">Alegro</option>
                    <option value="Alexânia">Alexânia</option>
                    <option value="Araxá">Araxá</option>
                    <option value="Atibaia">Atibaia</option>
                    <option value="Caeté">Caeté</option>
                  </SelectField>

                  <Label htmlFor="cargo">Cargo</Label>
                  <SelectField
                    value={cargo}
                    onChange={(e) => setCargo(e.target.value)}
                  >
                    <option value="">Selecione o Cargo</option>
                    <option value="Membro da Diretoria">
                      Membro da Diretoria
                    </option>
                    <option value="Gerente Geral">Gerente Geral</option>
                    <option value="Gerente Operacional">
                      Gerente Operacional
                    </option>
                    <option value="Gerente Corporativo">
                      Gerente Corporativo
                    </option>
                    <option value="Gestor">Gestor</option>
                    <option value="Coordenador">Coordenador</option>
                    <option value="Supervisor">Supervisor</option>
                    <option value="Emocionador">Emocionador</option>
                    <option value="Extraordinário">Extraordinário</option>
                    <option value="Gerente TCF">Gerente TCF</option>
                  </SelectField>

                  <Label htmlFor="setor">Setor</Label>
                  <SelectField
                    id="setor"
                    value={setor}
                    onChange={(e) => setSetor(e.target.value)}
                  >
                    <option value="" disabled>
                      Selecione o Setor
                    </option>
                    <option value="Cozinha">Cozinha</option>
                    <option value="Administrativo e Financeiro">
                      Administrativo e Financeiro
                    </option>
                    <option value="Agências">Agências</option>
                    <option value="Conservação e Limpeza">
                      Conservação e Limpeza
                    </option>
                    <option value="Contabilidade">Contabilidade</option>
                    <option value="CSC">CSC</option>
                    <option value="DP">DP</option>
                    <option value="ESG e SSO">ESG e SSO</option>
                    <option value="Eventos">Eventos</option>
                    <option value="Gerente de relacionamento">
                      Gerente de relacionamento
                    </option>
                    <option value="Gerente noturno">Gerente noturno</option>
                    <option value="Governança">Governança</option>
                    <option value="Inteligência do negócio">
                      Inteligência do negócio
                    </option>
                    <option value="Lavanderia">Lavanderia</option>
                    <option value="Lazer/Jota City">Lazer/Jota City</option>
                    <option value="Lojas">Lojas</option>
                    <option value="Mais Tauá">Mais Tauá</option>
                    <option value="Manutenção e Jardinagem">
                      Manutenção e Jardinagem
                    </option>
                    <option value="Marketing">Marketing</option>
                    <option value="Martech">Martech</option>
                    <option value="Minibar">Minibar</option>
                    <option value="Orçamento e gestão">
                      Orçamento e gestão
                    </option>
                    <option value="Parque Aquático">Parque Aquático</option>
                    <option value="Pós venda Mais Tauá">
                      Pós venda Mais Tauá
                    </option>
                    <option value="Qualidade e Experiência do Cliente">
                      Qualidade e Experiência do Cliente
                    </option>
                    <option value="Recepção/ Guest">Recepção/ Guest</option>
                    <option value="Reservas">Reservas</option>
                    <option value="Restaurante e bares">
                      Restaurante e bares
                    </option>
                    <option value="RM e distribuição">RM e distribuição</option>
                    <option value="Segurança">Segurança</option>
                    <option value="Setor">Setor</option>
                    <option value="SPA">SPA</option>
                    <option value="Supply Chain">Supply Chain</option>
                    <option value="TCF">TCF</option>
                    <option value="Tech Hub">Tech Hub</option>
                    <option value="Unisorriso">Unisorriso</option>
                    <option value="Vendas externas">Vendas externas</option>
                    <option value="Vendas Internas">Vendas Internas</option>
                    <option value="Extraordinário">Extraordinário</option>
                  </SelectField>

                  {/* {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                      {errorMessage && (
                    <ErrorComponent message={errorMessage} duration={8000} />
                  )}
 */}

                  <SubmitButton
                    type="submit"
                    style={{ backgroundColor: "#FFCC00", alignSelf: "center" }}
                  >
                    Confirmar
                  </SubmitButton>
                </Container>
              </Form>
            </Content>
          </ContentSection>
        </Container>
      </Container>
    </>
  );
};

export default UserAdd;
