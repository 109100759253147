import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "../styles/styles";
import {
  PrimaryColor,
  SecondaryColor,
  TextColor,
  TextColorLight,
  BackgroundColor,
  BackgroundColorLight,
  Accent,
  ErrorComponent,
  Border,
  Green,
  Blue,
} from "../styles/global";
import { BackBar } from "../styles/global";
import { ContentSection, Content } from "../styles/styles";

import breakpoints from "../styles/breakpoints";
import { styles } from "../pages/home/styles";
import { SvgUser } from "../assets/icons";
import coin1 from "../assets/icons/moeda-1.svg";
import coin5 from "../assets/icons/moeda-5.svg";
import coin10 from "../assets/icons/moeda-10.svg";
import coin50 from "../assets/icons/moeda-50.svg";
import coin100 from "../assets/icons/moeda-100.svg";
import { FaRegUserCircle } from "react-icons/fa";
import logo from "../assets/icons/logo-banco-da-felicidade.svg";
import { FiXCircle } from "react-icons/fi";
import { getUserData, getUserHistory } from "../controllers/userController";
import { getEmocionadores } from "../controllers/emocionarController";

const Header = styled.header`
  background: linear-gradient(185deg, ${Blue} 28%, ${Green} 0%);
  color: white;
  padding: 14px;
  padding-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 18px;

  .headerText {
    margin-top: 18px;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
  }
`;

const BackButton = styled.a`
  margin-top: 22px;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  width: fit-content;
`;

const Employee = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  .employee-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    text-align: left;

    h4 {
      color: #000;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .employee-details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0px;
    flex: 1;
    text-align: left;
    p,
    small {
      color: #000;
      font-size: 14px;
      width: max-content;
    }

    p {
      font-weight: 500;
    }
    small {
      font-weight: lighter;
    }
  }

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: ${Border};
    text-align: center;
    padding: 10px;
  }
  .employee-icon {
    font-size: 25px;
    color: #444;
  }
`;

const Caption = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  margin-top: 20px;

  > p {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color: #444;
  }
`;

const CoinSelection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const CoinItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  border: 1px solid ${Border};
  border-radius: 25px;
  position: relative;
  margin-left: 10px;

  img {
    position: absolute;
    left: -20px;
    width: 50px;
  }

  p {
    color: #444;
  }

  div {
    margin-left: 25px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  div span {
    font-size: 16px;
    color: #444;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 5px;
  }
`;

const CoinButton = styled.button`
  background: none;
  color: #444;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: none !important;
  }
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;
const Amount = styled.span`
  color: ${PrimaryColor};
  font-size: 18px;
  font-weight: 400;
  text-align: start;
`;

const ContinueButton = styled.button`
  background-color: ${Green};
  padding: 10px 70px;
  border-radius: 30px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Saldo = styled.div`
  width: 80%;
  background-color: #ffc107;
  color: #000;
  padding: 10px;
  border-radius: 10px;
  text-align: center;

  h3 {
    font-size: 14px;
    font-weight: 300;
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

function SelecionarMoedasRemover({
  emocionador,
  onConfirm,
  onBack,
  onClose,
  errorMessage,
}) {
  const [selectedMoedas, setSelectedMoedas] = useState(() => ({
    1: 0,
    5: 0,
    10: 0,
    50: 0,
    100: 0,
  }));

  const [moedasDistribuidas, setMoedasDistribuidas] = useState({});
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const usuarioId = user.idusuario;

  const handleMoedaChange = (value, amount) => {
    setSelectedMoedas((prevState) => {
      const currentAmount = prevState[value] || 0;
      const maxAmount = emocionador.saldo_moedas || 0;
  
    
      const updatedValue = currentAmount + amount;
  
    
      const finalValue = Math.max(0, Math.min(updatedValue, maxAmount));
  
      return {
        ...prevState,
        [value]: finalValue,
      };
    });
  };
  

  const canContinue = Object.values(selectedMoedas).some(
    (amount) => amount > 0
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const emocionadorData = await getEmocionadores(usuarioId);
        const savedMoedas = JSON.parse(
          localStorage.getItem("moedasADistribuir")
        );

        const moedas = emocionadorData?.saldo_moedas
          ? JSON.parse(emocionadorData.saldo_moedas)
          : savedMoedas || {};

        setMoedasDistribuidas(moedas);
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
      }
    };

    fetchData();
  }, [usuarioId]);

  return (
    <>
      <Header>
        <h1 className="headerText">Remover</h1>
        <BackButton onClick={onClose}>
          <FiXCircle />
        </BackButton>
      </Header>
      <Container direction="column" width="100%" padding="10px">
        <ContentSection fullWidth>
          <Content bgColor={Accent} align="center" gap="30px">
            <Employee fullWidth padding="0px">
              <div className="employee-name">
                <div className="employee-icon-div">
                  <FaRegUserCircle className="employee-icon" />
                </div>
                <h4>{emocionador.nome}</h4>
              </div>
              <div className="employee-details">
                <p>{emocionador.unidade}</p>
                <small>{emocionador.setor}</small>
              </div>
            </Employee>

            <Saldo>
              <h3>Saldo do Emocionador</h3>
              <h2>{emocionador.saldo_moedas}</h2>
            </Saldo>

            <CoinSelection>
              <Caption>
                <p>Moedas</p>
                <p>Saldo</p>
                <p>Quantidade para envio</p>
              </Caption>
              {Object.entries(selectedMoedas).map(([value, amount]) => {
                const saldoMoeda = emocionador.saldo_moedas;
        
                return (
                  <CoinItem key={value}>
                    <img
                      src={
                        value === "1"
                          ? coin1
                          : value === "5"
                          ? coin5
                          : value === "10"
                          ? coin10
                          : value === "50"
                          ? coin50
                          : coin100
                      }
                      alt={`${value} Moedas`}
                    />
                    <p>{saldoMoeda}</p>
                    <div>
                      <CoinButton
                        onClick={() => handleMoedaChange(value, -1)}
                        disabled={saldoMoeda <= 0 || amount <= 0} 
                        
                      >
                        -
                      </CoinButton>
                      <span>{amount}</span>
                      <CoinButton
                        onClick={() => handleMoedaChange(value, +1)}
                        disabled={saldoMoeda <= 0}
                      >
                        +
                      </CoinButton>
                    </div>
                  </CoinItem>
                );
              })}
            </CoinSelection>

            {/* {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>} */}
            {errorMessage && (
              <ErrorComponent message={errorMessage} duration={8000} />
            )}
            <ContinueButton
              onClick={() => onConfirm(selectedMoedas)}
              disabled={!canContinue}
            >
              Continuar
            </ContinueButton>
          </Content>
        </ContentSection>
      </Container>
    </>
  );
}

export default SelecionarMoedasRemover;
