import React, { useState } from "react";
import styled from "styled-components";
import Header from "../components/header";
import GlobalStyle from "../styles/global";
import SelecionarEmocionador from "../components/SelecionarEmocionador";
import SelecionarMoedas from "../components/SelecionarMoedas";
import JustificarRemocao from "../components/JustificarRemocao";
import ConfirmacaoRemocao from "../components/ConfirmacaoRemocao";
import { Container } from "../styles/styles";
import { PrimaryColor } from "../styles/global";
import { API_URL } from "../controllers/api";
import ListaEmocionadorRemover from "../components/listRemoveCoin";
import SelecionarMoedasRemover from "../components/SelecionarMoedasRemover";

const BackBar = styled(Container)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
  max-width: 100%;
`;

const BackButton = styled.a`
  display: flex;
  align-items: center;
  color: ${PrimaryColor};
  text-decoration: none;
  font-size: 22px;
  margin-bottom: 20px;
  width: fit-content;
`;

function RemoverMoedas({ onClose }) {
  const [step, setStep] = useState(1);
  const [emocionador, setEmocionador] = useState(null);
  const [moedas, setMoedas] = useState([]); // Array de moedas [{ valor: 1, quantidade: 5 }, { valor: 10, quantidade: 2 }]
  const [justificationData, setJustificationData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [moedasADistribuir, setMoedasADistribuir] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleSelectEmocionador = (selectedEmocionador) => {
    setEmocionador(selectedEmocionador);

    setStep(2);
  };
  const handleConfirmMoedas = async (moedas) => {
    setMoedas(moedas);

    setStep(3);
  };

  const handleJustificationSubmit = async (justification) => {
    setJustificationData(justification);

    try {
      const formData = new FormData();
      formData.append("distribuidorId", user.idusuario);
      formData.append("receptorId", emocionador.id);
      formData.append("moedas", JSON.stringify(moedas)); 
      formData.append("motivo", justification.reason || "");
      formData.append("descricao", justification.justification || "");
      formData.append("data_remocao", justification.date || "");

     

      const response = await fetch(API_URL + "/removerMoedas", {
        method: "POST",
        body: formData,
      });

      const result = await response.json();

      if (response.ok) {
        setStep(4);
      } else {
        setErrorMessage(
          result.mensagem || "Ocorreu um erro ao enviar os dados."
        );
      }
    } catch (error) {
      setErrorMessage(`Erro de comunicação com a API: ${error.message}`);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleClose = () => {
    window.location.href = "/";
  };

  return (
    <>
      <GlobalStyle />

      {step === 1 && (
        <ListaEmocionadorRemover
          onSelect={handleSelectEmocionador}
          onClose={handleClose}
        />
      )}
      {step === 2 && (
        <SelecionarMoedasRemover
          emocionador={emocionador}
          onConfirm={handleConfirmMoedas}
          onBack={handleBack}
          errorMessage={errorMessage}
          onClose={handleClose}
        />
      )}
      {step === 3 && (
        <JustificarRemocao
          emocionador={emocionador}
          moedas={moedas}
          onSubmit={handleJustificationSubmit}
          onBack={handleBack}
          errorMessage={errorMessage}
          onClose={handleClose}
        />
      )}
      {step === 4 && (
        <ConfirmacaoRemocao
          emocionador={emocionador}
          moedas={moedas}
          onClose={handleClose}
        />
      )}
    </>
  );
}

export default RemoverMoedas;
