import { useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { FiXCircle } from "react-icons/fi";
import styled from "styled-components";
import { SvgSearch } from "../assets/icons";
import { getEmocionadores } from "../controllers/emocionarController";
import GlobalStyle, {
  Border,
  Container,
  ErrorComponent,
  IconInput,
  PrimaryColor,
  Spinner,
} from "../styles/global";
import { Content, ContentSection } from "../styles/styles";

const Header = styled.header`
  height: 130px;
  background: linear-gradient(185deg, #ffcc00 28%, #ff9500 0%);
  color: white;
  padding: 14px;
  padding-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 18px;
  position: relative;

  .logo {
    left: 10px;
    top: 10px;
    position: absolute;
    align-items: start;
    width: 50px;
  }

  .headerText {
    margin-top: 18px;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
  }
`;

const BackButton = styled.a`
  margin-top: 22px;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  width: fit-content;
`;

const SearchInput = styled(IconInput)`
  margin-top: -10px;
  position: absolute;
  top: -20px;
`;

const EmployeeAll = styled(Container)`
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  h3 {
    color: ${PrimaryColor};
    font-size: 18px;
    font-weight: 400;
    text-align: start;
  }
`;

const RecentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;

  > h2 {
    color: ${PrimaryColor};
    font-size: 18px;
    font-weight: 400;
    text-align: start;
  }
`;

const EmployeeList = styled(Container)`
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  gap: 22px;
`;

const EmployeeItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    width: fit-content;
  }
  p {
    width: fit-content;
  }
  small {
    width: fit-content;
  }

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: ${Border};
    text-align: center;
    padding: 10px;
  }
  .employee-icon {
    font-size: 25px;
    color: #444;
  }

  .employee-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #000;
    text-align: left;
    flex: 1;

    h5 {
      color: #000;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .employee-details {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: start;
    text-align: right;
    flex: 0.5;

    p,
    small {
      font-size: 14px;
      font-family: "Roboto", sans-serif;
    }

    p {
      font-weight: 500;
      color: #000;
    }
    small {
      font-weight: lighter;
      color: #7e8392;
    }
  }
`;

function ListaEmocionadorRemover({ onSelect, onClose }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [emocionadores, setEmocionadores] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const usuarioId = JSON.parse(localStorage.getItem("user")).idusuario;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allData = await getEmocionadores(usuarioId);
        setEmocionadores(allData);
      } catch (error) {
        setErrorMessage("Erro ao carregar emocionadores.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [usuarioId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Spinner />
        <span
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          Carregando emocionadores...
        </span>
      </div>
    );
  }

  // const handleSelectEmocionador = (id) => {
  //   localStorage.setItem("selectedEmocionadorId", id);
  //   setSelectedEmocionadorId(id);
  //   if (onSelect) onSelect(id);
  // };

  const removeAcentos = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filteredEmocionadores = emocionadores.filter((emocionador) =>
    removeAcentos(emocionador.nome.toLowerCase()).includes(
      removeAcentos(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <GlobalStyle />
      <Header>
        <h1 className="headerText">Remover Emocionador</h1>
        <BackButton onClick={onClose}>
          <FiXCircle />
        </BackButton>
      </Header>
      <Container direction="column" width="100%">
        <ContentSection fullWidth padding="10px">
          <Content padding="0px" position="relative">
            <SearchInput>
              <SvgSearch />
              <input
                placeholder="Pesquisar por nome ou unidade"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </SearchInput>

            <EmployeeAll light padding="0px">
              <h3>Todos os Emocionadores</h3>
              <EmployeeList light>
                {filteredEmocionadores.map((emocionador) => (
                  <EmployeeItem
                    light
                    key={emocionador.idusuario}
                    onClick={() => {
                      onSelect(emocionador);
                    }}
                  >
                    <EmployeeInfo light>
                      <div className="employee-name">
                        <div className="employee-icon-div">
                          <FaRegUserCircle className="employee-icon" />
                        </div>
                        <h5>{emocionador.nome}</h5>
                      </div>
                      <div className="employee-details">
                        <p>{emocionador.unidade}</p>
                        <small>{emocionador.setor}</small>
                      </div>
                    </EmployeeInfo>
                  </EmployeeItem>
                ))}
              </EmployeeList>
            </EmployeeAll>

            {errorMessage && (
              <ErrorComponent message={errorMessage} duration={8000} />
            )}
          </Content>
        </ContentSection>
      </Container>
    </>
  );
}

export default ListaEmocionadorRemover;
