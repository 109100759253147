import React, { useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { FiXCircle } from "react-icons/fi";
import styled from "styled-components";
import coin1 from "../assets/icons/moeda-1.svg";
import coin10 from "../assets/icons/moeda-10.svg";
import coin100 from "../assets/icons/moeda-100.svg";
import coin5 from "../assets/icons/moeda-5.svg";
import coin50 from "../assets/icons/moeda-50.svg";
import { getUserData, getUserHistory } from "../controllers/userController";
import {
  Blue,
  Border,
  ErrorComponent,
  Green,
  PrimaryColor,
  TextColor,
  TextColorDark,
  TextColorLight,
  Yellow
} from "../styles/global";
import { Container, Content, ContentSection } from "../styles/styles";

const DateInput = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${TextColorLight};
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  text-align: start;
  justify-content: space-between;

  input {
    display: block;
    width: 100%;
    border: none;
    background: transparent;
    font-size: 14px;
  }

  svg {
    width: 30px;
    color: ${PrimaryColor};
  }

  div {
    display: flex;
    justify-content: start;
    align-items: center;
    width: fit-content;
    gap: 20px;
  }
`;

const Header = styled.header`
  background: linear-gradient(185deg, ${Blue} 28%, ${Yellow} 0%);
  color: white;
  padding: 14px;
  padding-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 18px;

  .headerText {
    margin-top: 18px;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
  }
`;

const BackButton = styled.a`
  margin-top: 22px;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  width: fit-content;
`;

const JustificationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  padding-block: 20px;
  border-radius: 10px;

  .moeda {
    width: 50px !important;
  }
`;

const Employee = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  .employee-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    text-align: left;

    h4 {
      color: #000;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .employee-details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0px;
    flex: 1;

    p,
    small {
      text-align: end;
      color: #000;
      font-size: 14px;
      width: max-content;
    }

    p {
      font-weight: 500;
    }
    small {
      font-weight: lighter;
    }
  }

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: ${Border};
    text-align: center;
    padding: 10px;
  }
  .employee-icon {
    font-size: 25px;
    color: #444;
  }
`;

const Label = styled.div`
  color: ${PrimaryColor};
  font-size: 18px;
  font-weight: 400;
  text-align: start;
`;

const SelectReason = styled(Container)`
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${Border};

  p {
    color: ${TextColorDark};
    padding: 0 5px;
    font-size: 12px;
  }

  select {
    background-color: transparent;
    border: none;
    color: #000;
    font-family: "Roboto", sans-serif;
    &:focus-visible {
      outline: 0px;
    }
  }
`;

const SubmitButton = styled.button`
  background-color: ${Green};
  padding: 10px 70px;
  border-radius: 30px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  cursor: pointer;
  align-self: center;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  min-height: 130px;
  color:  color: #000;

  &::placeholder {
    color: #000;
  }
`;

const JustificarEmocao = ({
  emocionador,
  moedas,
  onSubmit,
  onBack,
  onClose,
  errorMessage,
}) => {
  const currentDate = new Date().toISOString().split("T")[0];

  const [justification, setJustification] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [displayDate, setDisplayDate] = useState("");
  const [isoDate, setIsoDate] = useState(currentDate);
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [userData, setUserData] = useState({});
  const [history, setHistory] = useState([]);
  const [error, setError] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await getUserData(user.idusuario);
        setUserData(userInfo);

        const userHistory = await getUserHistory(user.idusuario);
        setHistory(userHistory);
      } catch (error) {
        setError("Erro ao carregar dados.");
      }
    };

    fetchData();
  }, [user.idusuario]);

  useEffect(() => {
    const currentDate = new Date();
    setDisplayDate(
      currentDate.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
    );
    setIsoDate(currentDate.toISOString());
  }, []);

 

  const handleReasonChange = (e) => setSelectedReason(e.target.value);

  const handleChange = (e) => setJustification(e.target.value);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      justification,
      reason: selectedReason,
      date: selectedDate,
      file,
    });
  };

  // const handleDateChange = (e) => {
  //   const selectedDate = new Date(e.target.value);
  //   setDisplayDate(selectedDate.toLocaleDateString('pt-BR', {
  //     day: '2-digit',
  //     month: 'long',
  //     year: 'numeric',
  //   }));
  //   setIsoDate(selectedDate.toISOString().split('T')[0]); // Data no formato ISO
  // };

  //const totalMoedas = Object.keys(moedas).reduce((total, value) => {
  //  return total + moedas[value] * parseInt(value);
  //}, 0);

  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      <Header>
        <h1 className="headerText">Remover Moedas</h1>
        <BackButton onClick={onClose}>
          <FiXCircle />
        </BackButton>
      </Header>
      <Container direction="column" width="100%" padding="10px">
        <ContentSection fullWidth padding="10px">
          <Content padding="0px">
            <JustificationForm onSubmit={handleSubmit}>
              <Label>Emocionador</Label>
              <Employee fullWidth padding="0px">
                <div className="employee-name">
                  <div className="employee-icon-div">
                    <FaRegUserCircle className="employee-icon" />
                  </div>
                  <h4>{emocionador.nome}</h4>
                </div>
                <div className="employee-details">
                  <p>{emocionador.unidade}</p>
                  <small>{emocionador.setor}</small>
                </div>
              </Employee>

              <Label>Moedas</Label>
              <Container padding="0px">
                {moedas["1"] > 0 && (
                  <img src={coin1} alt="1 Moeda" className="moeda" />
                )}
                {moedas["5"] > 0 && (
                  <img src={coin5} alt="5 Moedas" className="moeda" />
                )}
                {moedas["10"] > 0 && (
                  <img src={coin10} alt="10 Moedas" className="moeda" />
                )}
                {moedas["50"] > 0 && (
                  <img src={coin50} alt="50 Moedas" className="moeda" />
                )}
                {moedas["100"] > 0 && (
                  <img src={coin100} alt="100 Moedas" className="moeda" />
                )}
              </Container>

              <Container direction="column" gap="20px" padding="0px">
                <Label>Justificar Remoção:</Label>

                <TextArea
                  value={justification}
                  onChange={handleChange}
                  placeholder="Motivo da remoção"
                  required
                />

                <Container direction="column" gap="10px" padding="0px">
                  <Label>Data da remoção:</Label>
                  <DateInput>
                    <label htmlFor="date-picker">Selecione a data:</label>
                    <input
                      id="date-picker"
                      type="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      max={today}
                      required
                    />
                  </DateInput>
                </Container>
              </Container>

              {errorMessage && (
                <ErrorComponent message={errorMessage} duration={8000} />
              )}

              <SubmitButton type="submit">Confirmar</SubmitButton>
            </JustificationForm>
          </Content>
        </ContentSection>
      </Container>
    </>
  );
};

export default JustificarEmocao;
