import Inputmask from "inputmask";
import React, { useEffect, useState } from "react";
import logo from "../assets/icons/logo-banco-da-felicidade.svg";
import { loginUser } from "../controllers/userController";
import GlobalStyle, { IconInput } from "../styles/global";
import { FormContainer, LoginContainer, LoginSection } from "../styles/styles";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "../styles/global";

function Login() {
  const [cpf, setCpf] = useState("");
  const [senha, setSenha] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Inputmask("999.999.999-99").mask(document.getElementById("cpf"));
  }, []);

  const removeMaskCPF = (cpf) => {
    return cpf.replace(/\D/g, ""); // Remove tudo que não é número
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await loginUser(removeMaskCPF(cpf), senha);
      const user = response.usuario;
      toast.success("Usuário encontrado!");
      localStorage.setItem("user", JSON.stringify(user));
      setErrorMessage("");
      window.location.href = "/";
    } catch (error) {
      const errorMessage =
        error?.mensagem || "Erro inesperado. Por favor, tente novamente.";

      console.error("Erro ao logar:", errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <LoginSection>
        <LoginContainer>
          <img src={logo} alt="Logo Banco da Felicidade" />
          <h1>Bem-vindo!</h1>
          <form onSubmit={handleSubmit}>
            <FormContainer direction="column">
              <label htmlFor="cpf" style={{ color: "black" }}>
                Usuário
              </label>
              <input
                type="text"
                id="cpf"
                name="cpf"
                placeholder="Digite seu CPF"
                value={cpf}
                autoComplete="off"
                onChange={(e) => setCpf(e.target.value)}
                required
              />
            </FormContainer>

            <FormContainer direction="column">
              <label htmlFor="password" style={{ color: "black" }}>
                Senha
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Digite a sua senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                required
              />
            </FormContainer>

            <FormContainer direction="column">
              <button type="submit" disabled={loading}>
                {loading ? <Spinner /> : "Entrar"}
              </button>
            </FormContainer>

            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </form>
          <a href="/recuperar-senha">Esqueci minha senha</a>
        </LoginContainer>
        <div className="curve" />
        <div className="curve-red" />
      </LoginSection>
    </>
  );
}

export default Login;
